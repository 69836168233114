'use strict';

let isDarkTheme = false;
let darkModeEventListeners = [];
let mouse = {
    x: 0,
    y: 0,
};
let scrollY = 0;

window.addEventListener('load', () => {
    setupAbbrs();
    setupScrollListener();
    setupMouseMove();
    setupDarkModeToggle();
    if (window.innerWidth > 900) { // large enough to load webgl and hover views
        let script = document.createElement('script');
        script.src = '/assets/js/twgl.min.js';
        script.onload = setup3d;
        document.body.appendChild(script);

        // load hover view images
        for (const img of document.querySelectorAll('.hover-view img')) {
            img.src = img.dataset.src;
        }

        // open all links in new tab
        for (const link of document.links) {
            link.target = "_blank";
        }

        document.querySelector('.show-slider-content').addEventListener('click', function () {
            const content = document.querySelector('.slider-content');
            content.classList.toggle('show');
            this.classList.toggle('hide');
            this.setAttribute('tabindex', -1);
            setTimeout(() => {
                content.classList.toggle('show-animate');
            }, 20);
            content.scrollIntoView({
                behavior: 'smooth',
            });
        });

        setupHoverListeners();
    }
    setupTwemoji();

    console.log("%cThanks for looking at my site! If you want to see how I made it, I've included sourcemaps for the compiled JS and SCSS files. You can find them under `/assets/js/maps` and `/assets/css/maps`.", 'color:#ddd;font:16px sans-serif;line-height:24px;background:#222;padding:24px;border:2px solid #59f;border-radius:8px;');
});

// Emojis from Twitter's Twemoji: https://twemoji.twitter.com
function setupTwemoji() {
    twemoji.size = '72x72';
    twemoji.parse(document.querySelector(".mini-about .jobs"));
    twemoji.parse(document.querySelector(".mini-about .classes"));
}

function setupAbbrs() {
    for (const abbr of document.getElementsByTagName('abbr')) {
        abbr.dataset.title = abbr.title;
        abbr.removeAttribute('title');
    }
}

function setupHoverListeners() {
    const hoverableElements = document.querySelectorAll('.section [data-link]');

    for (let el of hoverableElements) {
        const hoverView = document.querySelector(`.hover-view [data-link="${el.dataset.link}"]`);

        if (!hoverView) {
            console.error(`Not found '${el.dataset.link}'`);
            continue;
        }

        const imgEl = hoverView.querySelector('img');

        el.addEventListener('mouseover', (_evt) => {
            if (imgEl.complete) {
                const pos = el.getBoundingClientRect();
                const x = pos.x - hoverView.offsetWidth - 16;
                const y = pos.y + window.scrollY - hoverView.offsetHeight / 2 + pos.height / 2;

                hoverView.style.left = x + 'px';
                hoverView.style.top = y + 'px';

                hoverView.classList.add('show');
            }
        }, { passive: true });
        el.addEventListener('mouseout', (_evt) => {
            hoverView.classList.remove('show');
        }, { passive: true });
    }
}

function setupMouseMove() {
    document.addEventListener('mousemove', (evt) => {
        mouse.x = evt.clientX;
        mouse.y = evt.clientY;
    }, { passive: true });
    document.addEventListener('touchmove', (evt) => {
        mouse.x = evt.targetTouches[0].clientX;
        mouse.y = evt.targetTouches[0].clientY;
    }, { passive: true });
}

function setupScrollListener() {
    const calculateScroll = () => {
        const maxScroll = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        scrollY = Math.min(1, window.scrollY / maxScroll);
    }
    document.addEventListener('scroll', calculateScroll, { passive: true });
    calculateScroll();
}

function setupDarkModeToggle() {
    isDarkTheme = (document.documentElement.dataset.theme === 'dark');
    const themeLinks = [document.querySelector('.dark-mode-toggle'), document.querySelector('.dark-mode-icon')];

    darkModeEventListeners.push(() => {
        document.documentElement.dataset.theme = (isDarkTheme ? 'dark' : 'light');
    });

    for (let themeSwitch of themeLinks) {
        themeSwitch.addEventListener('click', (e) => {
            isDarkTheme = !isDarkTheme;
            for (const func of darkModeEventListeners) {
                func();
            }
            e.preventDefault();
        }, false);
    }
}
